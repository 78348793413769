import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import * as Yup from "yup";

import SunRichTextEditor from "../RichTextEditor/SunRichTextEditor.js";
import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import ConfirmationModal from "../Setting/ConfirmationModal";
import SuccessPopup from "../Setting/Sucesspopup";

import {subscription, createEmailSettingForMember,getInviteeUsers,getRecentCourses,getAllMemberThatBuyMembership,getAllsubscription} from '../../helper/service.ts';
import useMembersPageStore from "../../stores/useMembersPageStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleLeft} from '@fortawesome/free-solid-svg-icons';
import searchicon from "../../images/admin/search-dark.svg";
import infoIcon from "../../images/dashboard/ic_error.svg";
import Loader from "../../Loader.js";

const CreateEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { emailSubject, emailMsg } = location.state || {};

    const [subject,setSubject]=useState(emailSubject || '');
    const [msgContent, setMsgContent]=useState(emailMsg || '');
    const [date,setDate] =useState('');
    const [isSchedule,setIsSchedule]=useState(false);
    const [attachments,setAttachments]=useState(null);
    const [attachmentsName,setAttachmentsName]=useState('');
    const [broadCast,setBroadCast]=useState(false);
    const [selectedAccessLevels, setSelectedAccessLevels] = useState([]);
    const [selectedCourseIds,setSelectedCourseIds]=useState([]);
    const [selectedIndividuals,setSelectedIndividuals]=useState([]);
    
    const [accessLevels, setAccessLevels] = useState(null);
    const [courseList, setCourseList] =useState([]);
    const [invitedMembers,setInvitedMembers] = useState([]);

    const [subscriptionData, setSubscriptionData] = useState("");
    const [individualData,setIndividualData]=useState('');
    const [courseMemberData,setCourseMemberData]=useState('');

    const [showWarningModal,setWarningModal]=useState(false);
    const [isLoading,setIsLoeading] =useState(false);
    const [isSuccess,setIsSuccess]=useState(false);
    const [mdlMessage,setMdlMessage]=useState('');
    const [modalType,setModalType]=useState('');
    const [modalTitle,setModalTitle]=useState('');
    
    const setMemberSearchQuery = useMembersPageStore((state) => state.setMemberSearchQuery);
    const memberSearchQuery = useMembersPageStore(
      (state) => state.memberSearchQuery
    );
  
    useEffect(() => {
      getSubscriptions();
      getIndividualUsers();
      fetchRecentCourses();
    }, []);
  
    useEffect(() => {
      subscriptionList(accessLevels);
    }, [selectedAccessLevels]);

    useEffect(() => {
      individualMemberList(invitedMembers);
    }, [selectedIndividuals]);

    useEffect(() => {
      courseMemberList(courseList);
    }, [selectedCourseIds]);

    async function fetchRecentCourses(){
      const datas = await getRecentCourses();
      
      if (datas?.result?.r1) {
        var currentDate = new Date();
        const filteredResults = datas.result.r1.filter(item => {
          if (!item.schedule && item.tUser > 0) {
            return true; 
          }
  
          const originalDate = new Date(item.schedule);
          const selectedTimeZone = localStorage.getItem('websiteTimeZone'); 
          const convertedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone, hour12: true });

          var scheduleDate = new Date(convertedDate);
          return (scheduleDate <= currentDate && item.tUser > 0);
        });
        
        setCourseList(filteredResults);
        courseMemberList(filteredResults);
      }
    }
    async function getIndividualUsers(){
      await getInviteeUsers().then(data=>{
        individualMemberList(data.result);
        setInvitedMembers(data.result);
      })
    }
    async function getSubscriptions() {
      getAllsubscription().then((res) => {
        let newArray = [];
        res.result.map((item) => {
          getAllMemberThatBuyMembership(item._id).then((i) => {
            item.memberCount = i.result.validSubs.length;
  
            newArray.push(item);
            newArray = newArray.sort((a, b) => {
              const dateA = new Date(a.name);
              const dateB = new Date(b.name);
              return dateA - dateB;
            });
            subscriptionList(newArray);
            setAccessLevels(newArray);
          });
        });
      });
    }
    const subscriptionList = (accessLevels) => {
      if (accessLevels) {
        const datas = accessLevels.map(function (accessLevel) {
          var checked = false;
  
          const found = selectedAccessLevels.find((ele) => {
            if (ele === accessLevel._id) {
              return true;
            } else {
              return false;
            }
          });
  
          if (found) {
            checked = true;
          }
          return (
            <div className="access-level mb-1" key={accessLevel._id}>
              <span className='float-left text-capitalize'>{accessLevel.tier.length > 25 ? `${accessLevel.tier.substring(0,20)}...` : accessLevel.tier}{accessLevel.memberCount > 0 ? ` (${accessLevel.memberCount})`:''}</span>
              <div className="custom-control custom-switch float-right">
                <input
                  type="checkbox"
                  checked={checked}
                  className="custom-control-input"
                  id={accessLevel._id}
                  name={accessLevel._id}
                  onChange={()=>selectAccessLevel(accessLevel._id)}
                />
                <label
                  className="custom-control-label cursor-pointer"
                  htmlFor={accessLevel._id}
                />
              </div>
            </div>
          );
        });
        setSubscriptionData(datas);
      }
    };
    const selectAccessLevel = (idd) => {
      const tempLevels = [...selectedAccessLevels];
      if (selectedAccessLevels.indexOf(idd) === -1) {
        tempLevels.push(idd);
        setSelectedAccessLevels([...tempLevels]);
      } else {
        const filteredLevels = selectedAccessLevels.filter(
          (level) => level !== idd
        );
        setSelectedAccessLevels([...filteredLevels]);
      }
      subscriptionList(accessLevels);
    };

    const individualMemberList = (membersData) => {
      if (membersData) {
        const datas = membersData.map(function (member) {
          var checked = false;
  
          const found = selectedIndividuals.find((ele) => {
            if (ele === member._id) {
              return true;
            } else {
              return false;
            }
          });
  
          if (found) {
            checked = true;
          }
          const fullName = `${member.firstName} ${member.lastName}`
          return(
            <div className="access-level mb-1" key={member._id}>
              <span className="float-left text-capitalize">{fullName.length > 25 ? `${fullName.substring(0,20)}...` : fullName}</span>
                <div className="custom-control custom-switch float-right">
                    <input
                    type="checkbox"
                    checked={checked}
                    className="custom-control-input"
                    id={member._id}
                    name={member._id}
                    onChange={()=>selectIndividualMember(member._id)}
                    />
                    <label
                    className="custom-control-label cursor-pointer"
                    htmlFor={member._id}
                    />
                </div>
            </div>
          )
        });
        setIndividualData(datas);
      }
    }
    const selectIndividualMember = (id) => {
      const tempMembers = [...selectedIndividuals];
      if (selectedIndividuals.indexOf(id) === -1) {
        tempMembers.push(id);
        setSelectedIndividuals([...tempMembers]);
      } else {
        const filteredMember = selectedIndividuals.filter(
          (member) => member !== id
        );
        setSelectedIndividuals([...filteredMember]);
      }
      individualMemberList(invitedMembers);
    }

    const courseMemberList = (coursesData) => {
      if (coursesData) {
        const datas = coursesData.map(function (course) {
          var checked = false;
  
          const found = selectedCourseIds.find((ele) => {
            if (ele === course._id) {
              return true;
            } else {
              return false;
            }
          });
  
          if (found) {
            checked = true;
          }
          return(
            <div className="access-level mb-1" key={course._id}>
              <span className='float-left text-capitalize'>{course.title.length > 25 ? `${course.title.substring(0,20)}...` : course.title}{` (${course.tUser})`}</span>
                <div className="custom-control custom-switch float-right">
                    <input
                    type="checkbox"
                    checked={checked}
                    className="custom-control-input"
                    id={course._id}
                    name={course._id}
                    onChange={()=>selectCourseMember(course._id)}
                    />
                    <label
                    className="custom-control-label cursor-pointer"
                    htmlFor={course._id}
                    />
                </div>
            </div>
          )
        });
        setCourseMemberData(datas);
      }
    }
    const selectCourseMember = (courseId) => {
      const tempCoursesId = [...selectedCourseIds];
      
      if (tempCoursesId.indexOf(courseId) === -1) {
          tempCoursesId.push(courseId);
          setSelectedCourseIds([...tempCoursesId]);
      } else {
          const filteredMember = tempCoursesId.filter(course => course !== courseId);
          setSelectedCourseIds([...filteredMember]);       
      }
      courseMemberList(courseList);
  };
  
  

    const resetStates = () => {
      setIsLoeading(false);
      setSubject('');
      setMsgContent('');
      setDate(new Date());
      setIsSchedule(false);
      setAttachments('');
      setAttachmentsName('');
      setBroadCast(false);
      setSelectedAccessLevels([]);
      setSelectedCourseIds([]);
      setSelectedIndividuals([]);
    }
    const countDescriptionWords = (text) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = text;
    
      const images = tempDiv.querySelectorAll('img');
      images.forEach(img => {
        img.outerHTML = 'X';
      });
    
      return tempDiv.innerText.trim().length;
    };
    const handlSunRichtextEditorChange = (content) => {
      const count = countDescriptionWords(content);
      if (count>2000) {
        setWarningModal(true);
        setMdlMessage("No more than 2000 characters allowed");
        setModalTitle("Action Required");
        setModalType("warning");
        return;
      } else if(count!==0){
        setMsgContent(content);
      }
      
    };

    const handleScheduleDateChange = (date) => {
      setDate(date);
    };

    const onResourcesChange = (event) => {
      
        if (event?.target?.files?.[0]?.size > 50e6) {
          setMdlMessage("Please upload a file smaller than 50 MB.");
          setWarningModal(true);
          setModalType("error");
          setModalTitle("Action Required");
          event.target.value = "";
          return;
        } else {
          if (event.target.files) {
            setAttachments(event?.target?.files?.[0]??'');
            setAttachmentsName(event.target.value);
          }
        }
    };
    
    const filteredMemberList = (memberList) => {
      if (!Array.isArray(memberList)) return [];
      if (!memberSearchQuery) return memberList;
  
      const result = memberList.filter((member) => {
        const name = `${member.firstName} ${member.lastName}`;
        return (
          name.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
          member.email.toLowerCase().includes(memberSearchQuery.toLowerCase())
        );
      });
  
      return result;
    };
    const searchMembers = (event) =>{
      event.preventDefault();
      
      if (event.target.value !== "") {
        setMemberSearchQuery(event.target.value);
        const filteredMember = filteredMemberList(invitedMembers);
        individualMemberList(filteredMember);
      } else {
        individualMemberList(invitedMembers);
      }
    }
    const searchCourses =(event) =>{
        event.preventDefault();
        const { value } = event.target;
        if(value!=='')
        {
          const filteredResult = courseList.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase())
          );
          courseMemberList(filteredResult);
        }
        else{
          courseMemberList(courseList);
        }
    }

    const submitForm = async (event) => {

      event.preventDefault();
      setIsLoeading(true);
      let formattedDate = "";
      let newformattedDate;
      
      if (date && isSchedule) {
        
        let timeZoneSelected = localStorage.getItem("websiteTimeZone");
        const onlyDate = new Date(date);
        const year = onlyDate.getFullYear();
        const month = onlyDate.getMonth() + 1; 
        const day = onlyDate.getDate();
        const scheduleDateOnly = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
          }${day}`;

        const utcDateTime = moment
          .tz(scheduleDateOnly, `${timeZoneSelected}`)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss");

        formattedDate = moment.utc(utcDateTime).toISOString();
        newformattedDate = date?.toLocaleDateString('en-GB', {
          weekday: 'short',   // "Fri"
          day: '2-digit',     // "06"
          month: 'short',     // "Sep"
          year: 'numeric'     // "2024"
        });
      } else if (!isSchedule) {
        formattedDate = "";
      }
      
      const data = {
        emailSubject: subject,
        emailMessage: msgContent,
        emailSchedule: formattedDate,
        memberAccessLevel: selectedAccessLevels,
        courseIds: selectedCourseIds,
        individuals: selectedIndividuals,
        files: attachments,
        usersIdslength : selectedAccessLevels.length + selectedCourseIds.length + selectedIndividuals.length,
      };
      
      const validationSchema = Yup.object().shape({
        emailSubject: Yup.string().required("Email Subject is required"),
        emailMessage: Yup.string().required("Email Content is required"),
        usersIdslength: Yup.number()
          .min(1, "At least one recipient is needed among the three above")
          .required("At least one recipient is needed among the three above"),
      });      
      
      

      validationSchema
        .validate(data)
        .then((validData) => {
        createEmailSettingForMember(validData).then(res=>{
          if(res.status===200){
            
            setIsSuccess(true);
            setMdlMessage(formattedDate===''
              ?
              "Email Send Successfully":
              "You have successfully scheduled your email to send on: " +`${newformattedDate}`
            );
            resetStates();
          }else{
            setWarningModal(true);
            setModalType('info');
            setModalTitle('FYI');
            setMdlMessage(`${res.message}`);
          }
        }).catch((error)=>{
          setIsLoeading(false);
          setWarningModal(true);
          setModalType('error');
          setModalTitle('Error!!');
          setMdlMessage(`${error}`);
        })
      }).catch((error)=>{
        setIsLoeading(false);
        setWarningModal(true);
        setModalType('error');
        setModalTitle('Error!!');
        setMdlMessage(`${error}`);
      })
    }

  return (
    <>
      {isLoading && <Loader/>}
      {isSuccess && <SuccessPopup message={mdlMessage} closeSuccessModel={()=>{setIsSuccess(false);setMdlMessage('')}}/>}
      {showWarningModal && 
      <ConfirmationModal 
        type={modalType}
        title={modalTitle}
        message={mdlMessage}
        onConfirm={()=>{setModalTitle('');setModalType('');setMdlMessage('');setWarningModal(false)}}
        onCancel={()=>{setModalTitle('');setModalType('');setMdlMessage('');setWarningModal(false)}}
      />}
      <Header />
      <NewSidebar />
      <div className="content-wrapper admin-courses">
        <div className="content-header mb-4 email-creation">
          <div className="row w-100 px-4">
            <span className="d-flex align-items-center fs-6 pointer w-25" style={{color:'#12aeb4'}} onClick={()=>navigate('/email')} >
            <FontAwesomeIcon icon={faCircleLeft} color="#12aeb4" style={{height:'25px',marginRight:'.5rem'}}/> Go Back
            </span>
          </div>
          <section className="email-messaging">
            <div className="content-fluid px-3 py-2">
              <div className="row mt-4 p-2">
                <div className="col-lg-6 col-md-8 col-sm-10">
                  <h2 className="title">Create Your Email</h2>
                  <div className="text">
                    <p>
                      Deliver messages to your members email inbox. Member can
                      reply to you directly via email to your company email that
                      you have set up. Member will recieve email from your
                      company email address.
                    </p>
                  </div>
                  <div>
                    <h2 className="heading">RECIPIENTS</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-evenly pr-2 gap-2 flex-wrap">
                  <div className="scroll">
                  <div className="form-group mb-0 mt-1">
                    <label>Access Level</label>
                    <div
                      className=""
                      style={{ paddingBottom: "0", border: "none" }}
                    >
                      <div className="access d-flex flex-column" >
                        {subscriptionData}
                      </div>
                    </div>
                    <span className="errormessage"></span>
                  </div>
                  <div className="form-group mb-0">
                    <div
                      className=""
                      style={{ paddingBottom: "0", border: "none" }}
                    >
                      <div className="access d-flex mt-2 p-0 align-items-center justify-content-center" style={{height:'50px'}}>
                        <div className="access-level" key={""}>
                            <div className="custom-control custom-switch float-left">
                                <input
                                type="checkbox"
                                checked={broadCast}
                                className="custom-control-input"
                                id={"broadCast"}
                                name={"broadCast"}
                                onChange={()=>setBroadCast(prev=>!prev)}
                                />
                                <label
                                className="custom-control-label cursor-pointer"
                                htmlFor={"broadCast"}
                                />
                            </div>
                            <span className="float-right small mt-1">Broadcast message on Member Homepage</span>
                        </div>
                      </div>
                    </div>
                    <span className="errormessage"></span>
                  </div>
                  </div>
                  <div className="scroll">
                    <label>Students in Courses</label>
                    <div className="search-members">
                      <input
                        type="text"
                        name="searchCourse"
                        className="form-control"
                        placeholder="Search courses"
                        onChange={searchCourses}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                        {courseMemberData}
                      </div>
                  </div>
                  <div className="scroll">
                    <label>Individuals</label>
                    <div className="search-members search-courses">
                      <input
                        type="text"
                        name="searchMember"
                        className="form-control"
                        placeholder="Search members"
                        onChange={searchMembers}
                      />
                      <span className="search-icon">
                        <img src={searchicon} />
                      </span>
                    </div>
                    <div className="access d-flex flex-column mt-3">
                      {individualData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="content-fluid mt-4 p-4 wis-modal w-75">
              <form name="form" onSubmit={submitForm}>
                <div className="form-group">
                  <label>
                    Subject 
                    <small> Max 500 Characters</small>
                  </label>
                  <input
                    type="text"
                    value={subject}
                    // required
                    maxLength={500}
                    name="subject"
                    className="form-control"
                    onChange={(event) => {
                      setSubject(event.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Message
                    <small> Max 2000 Characters</small>
                  </label>
                  <SunRichTextEditor
                    value={msgContent}
                    handlSunRichtextEditorChange={handlSunRichtextEditorChange}
                  />
                </div>

                <div className="form-group">
                  <div className="d-flex">
                    <label>Schedule</label>
                    <div className="custom-control custom-switch ml-3">
                      <input
                        type="checkbox"
                        checked={isSchedule}
                        className="custom-control-input"
                        id="emailSchedulesSwitch"
                        name="emailSchedule"
                        onChange={() => {
                        setIsSchedule(prev=>!prev);
                        }}
                      />
                      <label
                        className="custom-control-label cursor-pointer"
                        htmlFor="emailSchedulesSwitch"
                      />
                    </div>
                  </div>
                </div>
                        
                {isSchedule && (
                    <div className="form-group cursor-pointer w-50">
                    <DatePicker
                        selected={date}
                        onChange={handleScheduleDateChange}
                        name="startDate"
                        dateFormat="yyyy-MM-dd"
                        className="form-control date cursor-pointer"
                        minDate={moment().toDate()}
                        autoComplete="off"
                    />
                    </div>
                )}

                <div className="form-group w-50">
                  <label>
                    Attach Resource(s){" "}
                    <img src={infoIcon} width="16" className="mx-2" />
                    <small>
                      Add a new file direct from your device in PDF, Video,
                      Audio, Word doc, Excel Doc format.
                    </small>
                  </label>
                  <div className="position-relative">
                    <input
                      type="file"
                      className="form-control direct-add-file"
                      onChange={onResourcesChange}
                      name="attachments"
                      value={attachmentsName}
                    />
                  </div>
                </div>
                <div className="form-group">
                    <div className="d-flex gap-3">
                        {isSchedule && (
                          <button 
                            type='submit' 
                            value='schedule'
                            className="email-btn-custom"
                          >
                            Save Schedule
                            </button>
                          )}
                        <button type='submit' className="email-btn-custom" value='save'>Send</button>
                    </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CreateEmail;
