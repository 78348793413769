import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import SunRichTextEditor from '../RichTextEditor/SunRichTextEditor';
import * as Yup from "yup";

import {saveEmailSettingsForPartner} from '../../helper/service.ts';

import infoIcon from "../../images/dashboard/ic_error.svg";
import { Tooltip } from '@mui/material';
import ConfirmationModal from '../Setting/ConfirmationModal';
import SuccessPopup from '../Setting/Sucesspopup';



const Autoresponder = (props) => {
  const [weekCount,setWeekCount]=useState('');
  const [automate,setValueAutomate]=useState(false);
  const [showOptOut, setShowOptOut]=useState(false);
  const [footerContent,setFooterContent]=useState('');

  const [showWarningModal,setWarningModal]=useState(false);
  const [isSuccess,setIsSuccess]=useState(false);
  const [mdlMessage,setMdlMessage]=useState('');
  const [modalType,setModalType]=useState('');
  const [modalTitle,setModalTitle]=useState('');

  const resetState =()=>{
    setWeekCount('');
    setValueAutomate(false);
    setShowOptOut(false);
    setFooterContent('');
  }

  const handleWeekCount= (e)=>{
    var inputValue = e.target.value;
    var newValue = inputValue.replace(/\./g, "");
    if(newValue !== '' || newValue !== '0')
    {
      setWeekCount(newValue);
    }else{
      setWeekCount('');
    }
  }

  const countDescriptionWords = (text) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;
  
    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      img.outerHTML = 'X';
    });
  
    return tempDiv.innerText.trim().length;
  };
  const handlSunRichtextEditorChange = (content) => {
    const cleanedDescription = content.trim() === '<p><br></p>' ? '' : content;
    const wordCount = countDescriptionWords(cleanedDescription);

    if (wordCount>2000) {
      setWarningModal(true);
      setMdlMessage("No more than 2000 characters allowed");
      setModalTitle("Action Required");
      setModalType("warning");
      return;
    } else if(wordCount !== 0) {
      setFooterContent(cleanedDescription);
    }
  };

  const submitForm = () =>{
    
    const data={
      emailAutomation: automate,
      emailTimeReminder: weekCount,
      emailFooter: footerContent,
      displayOption: showOptOut,
    }
    const validationSchema = Yup.object().shape({
      emailAutomation : !weekCount ? Yup.boolean().optional() : Yup.boolean().required('Please toggle Automation button'),
      emailTimeReminder: Yup.string().when('emailAutomation', {
        is: true,
        then: Yup.string().required("Provide week value"),
        otherwise: Yup.string().notRequired()
      }),
      emailFooter: Yup.string().required("Email Footer is required"),
      displayOption: Yup.boolean().optional(),
    });
      
    validationSchema
      .validate(data)
      .then((validData) => {
        saveEmailSettingsForPartner(validData).then((resp)=>{
          if(resp.status===200){
            setIsSuccess(true);
            setMdlMessage("Email settings updated successfully");
            resetState();
          }
        }).catch((err)=>{
          setWarningModal(true);
          setModalType('error');
          setModalTitle('Error!!');
          setMdlMessage(`${err}`);
        })
    }).catch((err)=>{
        setWarningModal(true);
        setModalType('error');
        setModalTitle('Error!!');
        setMdlMessage(`${err}`);
    })
  }

  return (
    <>
    {isSuccess && <SuccessPopup message={mdlMessage} closeSuccessModel={()=>{setIsSuccess(false);setMdlMessage('')}}/>}
    {showWarningModal && 
    <ConfirmationModal 
      type={modalType}
      title={modalTitle}
      message={mdlMessage}
      onConfirm={()=>{setModalTitle('');setModalType('');setMdlMessage('');setWarningModal(false)}}
      onCancel={()=>{setModalTitle('');setModalType('');setMdlMessage('');setWarningModal(false)}}
    />}
    
    <div className="container-fluid autoresponder">
      <div className='row'>
        <div className="col-xl-12 mb-4">
          <h2 className='title'>Automations</h2>
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0'>
              <div className='card-body'>
                <div className='custom-control custom-switch float-left'>
                  <input
                    type="checkbox"
                    checked={automate}
                    className="custom-control-input"
                    id={'automate'}
                    name={'automate'}
                    onChange={()=>setValueAutomate(prev=>!prev)}
                  />
                  <label className="custom-control-label cursor-pointer" htmlFor={'automate'} />
                </div>
                <div>
                  <p>
                    Every <input type='number' className='week-count form-control' value={weekCount} onChange={(e)=>handleWeekCount(e)} /> week <b>send a reminder</b> to members who have not opened any emails.
                  </p>
                  <p className='last-action'>
                    Last Action Performed on: {"01-Sep-1997"}
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12 mb-4'>
          <h2 className='title pb-0'>Email Footer
            <Tooltip title={'This is the static footer that is displayed at the end of your emails'} placement={'right-start'}>
              <img src={infoIcon} width={16}/>
            </Tooltip>
          </h2>
          <small>Max 2000 Characters   </small>
            
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0'>
            <SunRichTextEditor
              value={footerContent}
              maxLength="2000"
              handlSunRichtextEditorChange={handlSunRichtextEditorChange}
            />
          </div>
          <div className='d-flex mt-4 ml-2'>
            <div className='custom-control custom-switch float-left mr-2'>
              <input
                type="checkbox"
                checked={showOptOut}
                className="custom-control-input"
                id={'showOptOut'}
                name={'showOptOut'}
                onChange={()=>setShowOptOut(prev=>!prev)}
              />
              <label className="custom-control-label cursor-pointer" htmlFor={'showOptOut'} />
            </div>
            <span>Display <b>'Opt out from email messaging'</b> at the bottom of the email footer </span>
          </div>
        </div>
        <div className='d-flex w-50 ml-2 gap-4'>
          <button type="button" className='btn email-btn-custom w-25' onClick={submitForm}>Save</button>
          <button type="button" className='btn email-btn-custom w-25 bg-white' style={{color:'#12aeb4'}} onClick={()=>{resetState();props.onClose();}}>Cancel</button>
        </div>
      </div>
      <div className='row mt-4'>
      <div className='col-xl-12 mb-4 mt-4'>
      <h2 className='title mb-0 pb-2'>Email Disclaimer</h2>
      <span>This can be viewed under settings.</span><Link  to='/settings/policy-pages' className='last-action pointer'>Click here to EDIT</Link>
      </div>
      </div>
    </div>
    </>
  )
}

export default Autoresponder;