import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Header from '../Common/Header'
import NewSidebar from '../new-sidebar'
import EmailList from './EmailList';
import Autoresponder from './Autoresponder';
import UnsubscribedListModal from './UnsubscribedListModal';
import SetEmailAddress from './SetEmailAddress';
import ConfirmationModal from '../Setting/ConfirmationModal';

import infoIcon from "../../images/dashboard/ic_error.svg";
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import SuccessPopup from '../Setting/Sucesspopup';

const settingTip ='Wisdome has a built-in email autoresponder that you can use to send email. When members reply to these emails you send out, they will arrive into your third-party email provider';

const Email = () => {
    const navigate =useNavigate();
    const [openAutomation,setOpenAutomation] = useState(false);
    const [isOpen,setIsOpen] = useState(false);
    const [isSetupOpen,setOpenSetup]=useState(false);
    const [showCreate,setShowCreate]=useState(true);
    const [isVerified,setIsVerified]=useState(false);
    
    const resetState =()=>{
        setOpenAutomation(false);
        setOpenSetup(false);
        setShowCreate(true);
        setIsVerified(false);
    }

  return (
    <>
    {isVerified && <SuccessPopup message="You have Confirmed Your New Email address" onCloseSuccessModel={()=>resetState()} />}
    <Header/>
    <NewSidebar/>
    <div className="content-wrapper admin-courses bg-white">
    <div className='content-header mb-4'>
    <section className='email-messaging'>
        <div className='content-fluid px-3 py-2'>
            <div className='row mt-4 p-2'>
                <div className='col-lg-6 col-md-8 col-sm-10'>
                    <h3 className='title'>Email Messaging</h3>
                    <div className='text' >
                        <p>
                            Deliver messages to your members email inbox. Member will recieve email from <b>your company email address.</b>
                            <p>Your email: <b>help@yourdomain.com.au</b></p>
                        </p>
                    </div>
                    
                    <div className='d-flex align-items-center flex-wrap gap-1 autoresponder'>
                        {/* {!showCreate && 
                        <Link 
                        className='last-action mr-3' 
                        onClick={()=>{
                            setOpenSetup(true);
                            setOpenAutomation(false)
                        }}>
                        Click here to confirm your Email Address
                        </Link>} */}

                        {showCreate && 
                        <button type="button" className='email-btn-custom mr-4 w-25 ' onClick={()=>{navigate('/create-email')}}>
                            <FontAwesomeIcon icon={faPlus} /> Create
                        </button>}
                        
                        <button type="button" 
                        className='email-btn-custom w-25' 
                        onClick={()=>{
                            setOpenAutomation(true);
                            setOpenSetup(false)}}
                        >
                            Settings
                        </button>
                        
                        <Tooltip title={settingTip} placement="top-start">
                            <span className='info-img'>
                                <img src={infoIcon} width={20}/>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        {/* <button type="button" onClick={()=>setIsOpen(true)}>modal</button>
        <button type="button" onClick={()=>setShowCreate(prev=>!prev)}>show</button>
        <button type="button" onClick={()=>setShowCreate(prev=>!prev)}>verify</button> */}
        
    </section>
    </div>
    
    {(isSetupOpen && !openAutomation) &&  (<SetEmailAddress onClose={()=>resetState()}/>)}

    {(openAutomation && !isSetupOpen) && (
        <section>
            <Autoresponder onClose={()=>resetState()}/>
        </section>
    )}
    
    {(!openAutomation && !isSetupOpen) && (
        <section className="courses-list">
            <EmailList />
        </section>
    )}
    
    {isOpen && <UnsubscribedListModal onClose={()=>setIsOpen(false)}/>}

    {!showCreate && <ConfirmationModal type='verify' title="Confirm your new sender email address" message='jeon.jungkook@jin.hit.com' 
    onCancel={()=>resetState()} 
    onConfirm={()=>{
        setIsVerified(true);
        setShowCreate(false);
    }}/>
    }

    </div>
    </>
  )
}

export default Email