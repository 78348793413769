import React,{useState} from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'

import close from "../../images/dashboard/modal-close.svg";

const UnsubscribedListModal = (props) => {
    const [isOpen, setisOpen] = useState(true);

    const handleCloseModal = () => {
        setisOpen(false);
        props.onClose();
    };
  return (
    <Modal 
    className="wis-modal modal-wide email-detail-modal"
    show={isOpen}
    onHide={handleCloseModal}
    backdrop="static">
    <ModalHeader>
        <h5 className="modal-title pl-0 text-center">
            Unsubscribed List
        </h5>
        <button
            type="button"
            className="close ml-5"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
        >
        <span aria-hidden="true">
            <img src={close} />
        </span>
        </button>
    
    </ModalHeader>
    <ModalBody>
        <div className='d-flex justify-content-center align-items-center mb-4 flex-column'>
            <div className='form-group'>
                <label>Total Unsubscribed: </label>
                <span className='fs-6 mt-2'> 20</span>
            </div>
            <div className='form-group'>
                <label>Total Subscribed: </label>
                <span className='fs-6 mt-2'> 2</span>
            </div>
        </div>
        <div className='d-flex justify-content-left'>
            <table className='w-100'>
                <tr className='mb-2'>
                    <td>Email</td>
                    <td>Plan</td>
                    <td>Date Unsubscribed</td>
                </tr>
                <tr>
                    <td>kim@taehyung.hybe.com</td>
                    <td>Layover</td>
                    <td>31 Dec 1995</td>
                </tr>
            </table>
        </div>
    
    </ModalBody>
    </Modal>
  )
}

export default UnsubscribedListModal