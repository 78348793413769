import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";

const ConfirmationModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [classes, setClasses] = useState("justify-content-center");
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (props.type === "delete" || props.type === "logout" || props.type === "unenroll") {
      setClasses("justify-content-end");
    }

    $(document).ready(function () {
      $(".multiPurposePopup").attr("id", "confirmationModal");
    });
  }, [props.type]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      <Modal
        className="multiPurposePopup"
        show={isOpen}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header>
          {(props.type === "info" && (
            <div
              className="w-30 h-30 d-flex rounded-3 p-1 mt-3 text-white"
              style={{ backgroundColor: "#00AEB3" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
              </svg>
            </div>
          )) ||
            (props.type === "success" && (
              <div
                className="w-30 h-30 d-flex rounded-3 p-1 mt-3 text-white"
                style={{ backgroundColor: "rgb(60, 179, 113)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                </svg>
              </div>
            )) ||
            (props.type === "warning" && (
              <div
                className="w-30 h-30 d-flex rounded-5 p-1 mt-3 "
                style={{ background: "rgba(255,204,0,0.2)", color: "rgb(255,204,0)", border: "1px solid rgb(255,204,0)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-exclamation-triangle"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "1px" }}
                >
                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
              </div>
            )) ||
            (props.type === "delete" && (
              <div
                className="w-30 h-30 d-flex border border-4 border-danger rounded-5 p-1 mt-3 text-danger"
                style={{ background: "rgba(255,0,0,0.2)" }}
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                </svg>
              </div>
            )) ||
            (props.type === "error" && (
              <div
                className="w-30 h-30 d-flex rounded-3 p-1 mt-3 text-white"
                style={{ backgroundColor: "rgb(255, 0, 0)" }}
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-x-octagon-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                </svg>
              </div>
            )) ||
            (props.type === "logout" && (
              <div
                className="w-30 h-30 d-flex rounded-5 p-1 mt-3 "
                style={{ background: "rgba(255, 170, 0, 0.2)", color: "rgb(255, 170, 0)", border: "1px solid rgb(255, 170, 0)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-door-closed"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "1px" }}
                >
                  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z" />
                  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0" />
                </svg>
              </div>
            )) ||
            (props.type === "unenroll" && (
              <div
                className="w-30 h-30 d-flex border border-4 border-danger rounded-5 p-1 mt-3 text-danger"
                style={{ background: "rgba(255,0,0,0.2)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-door-closed"
                  viewBox="0 0 16 16"
                  style={{ marginBottom: "1px" }}
                >
                  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z" />
                  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0" />
                </svg>
              </div>
            ))}
          <div className={props.type==='verify'?'text-center':''}>
            <h4 className="title mt-3">{props.title}</h4>
            <p className="text-sm text-secondary">{props.message}</p>
          </div>
        </Modal.Header>
        <Modal.Body
          className={`d-flex bg-light rounded-3 ${classes}`}
        >
          {(props.type === "info" && (
            <button
              onClick={closeModal}
              type="button"
              className="btn text-white"
              style={{ background: "#00AEB3" }}
            >
              {props.btnText ? props.btnText : "OK"}
            </button>
          )) ||
            (props.type === "success" && (
              <button
                onClick={closeModal}
                type="button"
                className="btn text-white fs-6"
                style={{ background: "rgb(60, 179, 113)" }}
              >
                {props.btnText ? props.btnText : "OK"}
              </button>
            )) ||
            (props.type === "delete" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn text-black border border-1 mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn btn-danger"
                >
                  {props.btnText ? props.btnText : "Delete"}
                </button>
              </>
            )) ||
            (props.type === "warning" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn text-black border border-1 border-warning mx-2 bg-warning"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  OK
                </button>
              </>
            )) ||
            (props.type === "error" && (
              <button
                onClick={closeModal}
                type="button"
                className="btn text-white fs-6"
                style={{ background: "rgb(255, 0, 0)" }}
              >
                {props.btnText ? props.btnText : "OK"}
              </button>
            )) ||
            (props.type === "logout" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn text-black border border-1 mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"

                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn hover-dark mx-2"
                  style={{
                    backgroundColor: hover ? '#a45c05' : '#FF8C00',
                    borderColor: hover ? '#a45c05' : '#FF8C00',
                    color: 'white',
                    transition: 'background-color 0.3s, border-color 0.3s'
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {props.btnText ? props.btnText : "Logout"}
                </button>
              </>
            )) ||
            (props.type === "unenroll" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn text-black border border-1 mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn btn-danger"
                >
                  {props.btnText ? props.btnText : "Confirm"}
                </button>
              </>
            ))||
            (props.type === "verify" && (
              <>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn email-btn-custom mx-2 bg-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{color:'#12aeb4'}}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    props.onConfirm();
                  }}
                  type="button"
                  className="btn email-btn-custom"
                >
                  {props.btnText ? props.btnText : "Confirm"}
                </button>
              </>
            ))
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
